import React from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

// ** MUI Imports
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import MuiAppBar from '@mui/material/AppBar'
import MuiToolbar from '@mui/material/Toolbar'
import { useMediaQuery } from '@mui/material'
import { Settings, Logout, Person, Menu as MenuIcon } from '@mui/icons-material'

// ** Util Import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 6),
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}))

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  padding: '0 !important',
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition:
    'padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: `${theme.spacing(4)} !important`,
    paddingRight: `${theme.spacing(4)} !important`,
  },
}))

function LayoutAppBar(props) {
  // ** Props
  const { settings, toggleNavVisibility } = props
  const user = useSelector((state) => state.authentication.user)
  const router = useRouter()

  // ** Hooks
  const theme = useTheme()
  const scrollTrigger = useScrollTrigger({
    threshold: 0,
    disableHysteresis: true,
  })

  // ** Vars
  const {
    skin, appBar, appBarBlur, contentWidth,
  } = settings
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  const appBarFixedStyles = () => ({
    px: `${theme.spacing(6)} !important`,
    ...(appBarBlur && { backdropFilter: 'blur(8px)' }),
    boxShadow: theme.shadows[skin === 'bordered' ? 0 : 3],
    backgroundColor: hexToRGBA(
      theme.palette.background.paper,
      appBarBlur ? 0.9 : 1,
    ),
    ...(skin === 'bordered' && {
      border: `1px solid ${theme.palette.divider}`,
      borderTopWidth: 0,
    }),
  })

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const goProfile = () => {
    setAnchorEl(null)
    router.replace('/profile')
  }
  const logout = () => {
    router.replace('/login')
  }

  if (appBar === 'hidden') {
    return null
  }

  return (
    <AppBar
      elevation={0}
      color="default"
      className="layout-navbar"
      position={appBar === 'fixed' ? 'sticky' : 'static'}
    >
      <Toolbar
        className="navbar-content-container"
        sx={{
          ...(appBar === 'fixed'
            && scrollTrigger && { ...appBarFixedStyles() }),
          ...(contentWidth === 'boxed' && {
            '@media (min-width:1440px)': {
              maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)`,
            },
          }),
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            className="actions-left"
            sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
          >
            {hidden ? (
              <IconButton
                color="inherit"
                sx={{ ml: -2.75 }}
                onClick={toggleNavVisibility}
              >
                <MenuIcon />
              </IconButton>
            ) : null}
          </Box>
          <Box
            className="actions-right"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'flex-end',
                // marginTop: "10px",
              }}
            >
              <Typography>
                {user?.client?.first_name}
                {' '}
                {user?.client?.last_name}
              </Typography>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                {user?.client?.avatar ? (
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    src={user?.client?.avatar}
                  />
                ) : (
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {user?.client?.first_name?.substr(0, 1).toUpperCase()}
                  </Avatar>
                )}
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={goProfile}>
                <ListItemIcon>
                  <Person fontSize="small" />
                </ListItemIcon>
                {'Profile'}
              </MenuItem>
              {/* <Divider /> */}
              {/* <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem> */}
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {'Logout'}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default LayoutAppBar
