import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import {
  GridView,
  VideogameAsset,
  PermIdentity,
  School,
  RocketLaunch,
} from '@mui/icons-material'

import themeConfig from 'src/configs/themeConfig'
import { useSettings } from 'src/@core/hooks/useSettings'
import Drawer from 'src/@core/layouts/components/vertical/navigation/Drawer'
import AppBar from 'AppBar'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex',
})

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
})

function Navigation(props) {
  return (
    <Drawer {...props}>
      <Box sx={{ padding: 4 }}>
        <StyledLink href="/">
          <img width={96} alt="logo" src="/images/1k-logo.svg" />
        </StyledLink>
      </Box>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <List className="nav-items" sx={{ pt: 0 }}>
          <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
            <Button
              variant="link"
              fullWidth
              href="/profile"
              sx={{ justifyContent: 'start', textTransform: 'capitalize' }}
            >
              <PermIdentity sx={{ mr: 2 }} />
              {'Partner Profile'}
            </Button>
          </ListItem>
          <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
            <Button
              variant="link"
              fullWidth
              href="/games"
              sx={{ justifyContent: 'start', textTransform: 'capitalize' }}
            >
              <VideogameAsset sx={{ mr: 2 }} />
              {'My Games'}
            </Button>
          </ListItem>
          <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
            <Button
              variant="link"
              fullWidth
              href="/collectibles"
              sx={{ justifyContent: 'start', textTransform: 'capitalize' }}
            >
              <GridView sx={{ mr: 2 }} />
              {'My Collectibles'}
            </Button>
          </ListItem>
          <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
            <Button
              variant="link"
              fullWidth
              href="https://docs.1kin.io"
              target="_blank"
              sx={{ justifyContent: 'start', textTransform: 'capitalize' }}
            >
              <School sx={{ mr: 2 }} />
              {'API Integration Docs'}
            </Button>
          </ListItem>
          {process.env.NEXT_PUBLIC_APP_ENV !== 'production' && (
            <ListItem disablePadding className="nav-link" sx={{ mt: 1.5 }}>
              <Button
                variant="link"
                fullWidth
                href="https://partner.1kin.io"
                sx={{ justifyContent: 'start', textTransform: 'capitalize' }}
              >
                <RocketLaunch sx={{ mr: 2 }} />
                {'Go to Production'}
              </Button>
            </ListItem>
          )}
        </List>

        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
          }}
        >
          <Box sx={{ px: 3, pt: 6, pb: 4 }}>
            <Box
              sx={{
                p: 4,
                backgroundColor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  py: 2,
                  px: 4,
                  backgroundColor: 'customColors.darkBg',
                  borderRadius: 1,
                  mb: 3,
                }}
              >
                <Typography variant="h6">{'We’re in ALPHA! 🎉'}</Typography>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ fontSize: '13px', mb: 3 }}>
                  {'Let us know what you think of the experience!'}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ width: '100%', mb: 3 }}
                  target="blank"
                  href="https://forms.gle/XVhL7Qg99tDrHmMw5"
                >
                  {'GIVE US FEEDBACK'}
                </Button>
                <Typography
                  variant="body1"
                  sx={{ fontSize: '13px', fontStyle: 'italic', mb: 3 }}
                >
                  {'If you come across any bugs in your travels, tell us about'}
                  {'them here:'}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ width: '100%' }}
                  target="blank"
                  href="https://forms.gle/xbZDYzwgVFDi5EzX7"
                >
                  {'SEND BUG REPORT'}
                </Button>
              </Box>
            </Box>
          </Box>
          <Typography sx={{ mr: 2, px: 8, py: 6 }}>
            {`© ${new Date().getFullYear()}, 1Kin Labs `}
          </Typography>
        </Box>
      </Box>
    </Drawer>
  )
}

export default Navigation

function MenuLayout({ children }) {
  const { settings } = useSettings()

  const { skin, contentWidth } = settings
  const { navigationSize, collapsedNavigationSize } = themeConfig

  const navWidth = navigationSize
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0
  const collapsedNavWidth = collapsedNavigationSize

  // ** States
  const [navHover, setNavHover] = useState(false)
  const [navVisible, setNavVisible] = useState(false)
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible)

  const mediaSx = {
    mx: 'auto',
    '@media (min-width:1440px)': { maxWidth: 1440 },
    '@media (min-width:1200px)': { maxWidth: '100%' },
  }

  return (
    <VerticalLayoutWrapper className="layout-wrapper">
      <Navigation
        hidden={hidden}
        navWidth={navWidth}
        navHover={navHover}
        navVisible={navVisible}
        setNavHover={setNavHover}
        setNavVisible={setNavVisible}
        collapsedNavWidth={collapsedNavWidth}
        toggleNavVisibility={toggleNavVisibility}
        navigationBorderWidth={navigationBorderWidth}
        settings={settings}
      />
      <MainContentWrapper className="layout-content-wrapper">
        <AppBar
          toggleNavVisibility={toggleNavVisibility}
          settings={settings}
        />

        <ContentWrapper
          className="layout-page-content"
          sx={{
            ...(contentWidth === 'boxed' && mediaSx),
          }}
        >
          {children}
        </ContentWrapper>
      </MainContentWrapper>
    </VerticalLayoutWrapper>
  )
}

export function Authenticated({ children }) {
  const router = useRouter()

  const user = typeof localStorage !== 'undefined' && localStorage?.getItem('user')
    ? JSON.parse(localStorage?.getItem('user') || '{}')
    : null

  useEffect(() => {
    if (!user || !user.client) {
      // not logged in so redirect to login page with the return url
      router.push('/login')
    }
  }, [user, router])

  return (
    <MenuLayout>
      {children}
    </MenuLayout>
  )
}
